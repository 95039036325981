import { Size, Cord } from "../interfaces"
import { Pattern } from './Pattern'

export class Acorn extends Pattern {
  constructor() {
    super()
  }
  public build(): Cord[] {
    const { x, y } = this._base
    return [
      { x: x + 2, y: y + 1 },
      { x: x + 1, y: y + 3 },
      { x: x + 2, y: y + 3 },
      { x: x + 4, y: y + 2 },
      { x: x + 5, y: y + 3 },
      { x: x + 6, y: y + 3 },
      { x: x + 7, y: y + 3 }
    ]
  }
}

