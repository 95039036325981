
import { Size, Cord } from "../interfaces"
import { Pattern } from './Pattern'

export class PillarPulse extends Pattern {
  private _count: number = 1
  private _random: boolean = false

  public set count(c: number) { this._count = c }
  public set random(v: boolean) { this._random = v }

  constructor(protected _grid_count: Size) {
    super()
  }

  public build(): Cord[] {
    let cells: Cord[] = [];
    let points: Cord[] = [];
    const { w, h } = this._grid_count

    if (this._random) {
      for (let i = 0; i < this._count; i++)
        points.push({
          x: Math.floor(Math.random() * w),
          y: Math.floor(Math.random() * h)
        })

    }
    else {
      const { w, h } = this._grid_count
      let count: number = this._count

      for (let i = 1; i <= this._count; i++) {
        const c: Cord = {
          x: Math.floor(w / (2 + i)),
          y: 20
        }
        points.push(c)
      }

      //   { x: 40, y: 20 },
      //   { x: w - 49, y: 20 },
      //   { x: Math.floor(w / 2), y: 20 }
      // ]
    }


    points.forEach((p: Cord) =>
      cells = [...cells, ...this.v_line(p, 25)])

    return cells;
  }

}