import { Size, Cord, Color, CellState } from "./interfaces"

import { CellLight } from "./CellLight"
import * as PIXI from 'pixi.js'
import { Acorn } from "./Patterns/Acorn"



export class World {

  public interval: number = -1;
  public rgb: Color = { r: 255, g: 0, b: 0 }
  public raw_time: number = 0
  public seconds: number = 0
  private _cells: CellLight[] = []
  private _gfx: PIXI.Graphics = new PIXI.Graphics()

  // private generator: Generator = new Generator()
  public interval_rate: number = 2000
  public pillars_random_spawn: boolean = false


  // private _cells_gfx: PIXI.Graphics = new PIXI.Graphics()

  private box_width: number = 8
  private box_height: number = 8
  protected grid_width = window.innerWidth
  protected grid_height = window.innerHeight
  protected row_count = Math.floor((this.grid_height * 1) / this.box_height)
  protected col_count = Math.floor((this.grid_width * 1) / this.box_width)

  constructor() { }

  public kill_first_cell(): void {
    if (this._cells.length)
      this.deactivate(this._cells[0].grid_xy())
  }

  public grid_size(): Size {
    return { w: this.grid_width, h: this.grid_height }
  }

  public grid_count(): Size {
    return { w: this.col_count, h: this.row_count }
  }

  public box_size(): Cord {
    let x = this.box_height;
    let y = this.box_width;

    return { x, y }
  }

  public gfx(): PIXI.Graphics {
    return this._gfx
  }
  //- c: x,y grid index space 
  public activate(c: Cord, immunity: boolean = false): void {
    const { box_width } = this,
      { box_height } = this


    //- create a cell here    
    let position: Cord = { x: c.x * box_width, y: c.y * box_height }
    let cell = new CellLight(this.raw_time, position, box_width, box_height)

    if (immunity && !cell.immune())
      cell.grant_immunity()

    if (cell.state() === CellState.dead) {
      cell.birth()
      cell.update_state()
      this._cells.push(cell)
    }
  }

  public deactivate(c: Cord): void {
    let cell: CellLight = this.get_cell(c)
    cell.remove_immunity()
    cell.death()
    cell.update_state()
    this._cells = this._cells.filter(o => o !== cell)
  }

  public remove_dead_cells(): void {
    this._cells = this._cells.filter(cell => (
      cell.state() === CellState.alive && cell.new_state() === CellState.alive
    ))
  }

  public add_birth_cells(cords: Cord[]): void {
    cords.forEach((cord: Cord) => {
      cord = { x: cord.x * this.box_width, y: cord.y * this.box_height }
      let cell = new CellLight(this.raw_time, cord, this.box_width, this.box_height)
      cell.birth()
      cell.update_state()
      this._cells.push(cell)
    })
  }

  public build(): void {



    //- TODO: move this to GameOfLife.ts
    // this.generator.create()

    // Acorn
    let a: Acorn = new Acorn()
    a.base = { x: 5, y: 5 }
    this.activate_pattern(a.build())

    // this.build_acorn()
    // this.build_acorn()

    // this.toggle_build_pillar_pulses()


  }

  // //- TODO: CREATE INTERVAL MANAGER
  // public change_interval(ir: number): void {
  //   this.interval_rate = ir;
  //   window.clearInterval(this.interval)
  //   this.start_interval()
  // }

  //- TODO: STATE FOR COLLECTIONS
  // public toggle_pillars_random_spawn(): void {
  //   this.pillars_random_spawn = !this.pillars_random_spawn
  // }

  // private start_interval(): void {
  //   this.interval = window.setInterval(() => {
  //     this.build_pillars()
  //   }, this.interval_rate)
  // }

  // public toggle_build_pillar_pulses(): void {

  //   if (this.interval > 0) {

  //     window.clearInterval(this.interval)
  //     this.interval = -1
  //   }
  //   else {

  //     this.build_pillar_pulses()
  //   }
  // }

  // public build_pillar_pulses(): void {

  //   this.build_pillars()

  //   if (this.interval > 0) {
  //     window.clearInterval(this.interval)
  //     this.interval = -1
  //   }

  //   this.start_interval()
  // }

  // private build_pillars(): void {
  // const c: Collection = new Collection()
  // c._grid_count = this.grid_count()
  // const cells: Cord[] = c.pillar_pulse(this.pillars_random_spawn)
  // this.build_pattern(cells)
  // }

  public activate_pattern(pattern: Cord[]): void {
    if (pattern.length)
      pattern.forEach((c: Cord) => this.activate({ x: c.x, y: c.y }, true))
  }

  public get_random_cell(): Cord {
    const { w, h } = this.grid_count()
    return {
      x: Math.floor(Math.random() * w),
      y: Math.floor(Math.random() * h)
    }
  }

  public build_acorn(): void {
    // const c: Collection = new Collection()
    // const cord: Cord = {
    //   x: Math.floor(Math.random() * (this.grid_count().w - 50)),
    //   y: Math.floor(Math.random() * (this.grid_count().h - 50))
    // }
    // let cells: Cord[] = c.build_acorn(cord)
    // cells.forEach((c: Cord) => this.activate({ x: c.x, y: c.y }, true))
  }

  public clear(): void {
    //- clear all cells
    this._cells.forEach((c: CellLight) => {
      c.remove_immunity()
      c.death()
      c.update_state()
    })
    this._cells = []
  }

  public to_grid_cell_space(point: PIXI.Point): Cord {
    let box_size: Cord = this.box_size()
    let grid_xy: Cord = {
      x: Math.floor(point.x / box_size.x),
      y: Math.floor(point.y / box_size.y)
    }
    return grid_xy;
  }

  //- grid space
  public get_cell(p: Cord): CellLight {
    let cell = new CellLight(this.raw_time, {
      x: p.x * this.box_width,
      y: p.y * this.box_height
    }, this.box_width, this.box_height) //- default, undefined
    const found = this._cells.find(cell =>
      (cell.grid_xy().x == p.x && cell.grid_xy().y == p.y))
    if (found !== undefined) return found
    return cell

  }

  public get_alive_neighbors(c: Cord): CellLight[] {
    let neighbors: CellLight[] = this.get_neighbors(c)
    return neighbors.filter((o: CellLight) => (o.state() === CellState.alive))
  }

  public get_dead_neighbors(c: Cord): CellLight[] {
    let neighbors: CellLight[] = this.get_neighbors(c)
    return neighbors.filter((o: CellLight) => (o.state() === CellState.dead))
  }

  public get_neighbors(c: Cord): CellLight[] {
    let neighbors: CellLight[] = []
    const x: number = c.x,
      y: number = c.y

    const neighbor_points: Cord[] = [
      { x: x - 1, y: y - 1 },   //- check top left
      { x: x, y: y - 1 },       //- check top
      { x: x + 1, y: y - 1 },   //- check top right
      { x: x - 1, y },          //- check left
      { x: x + 1, y },          //- check right
      { x: x - 1, y: y + 1 },   //- check bottom left
      { x: x, y: y + 1 },       //- check bottom
      { x: x + 1, y: y + 1 }    //- check bottom right
    ]

    neighbor_points.forEach((c: Cord) => neighbors.push(this.get_cell(c)))
    return neighbors
  }

  //- returns all empty cells next to any alive cell
  public get_adjacent_cells(): Cord[] {
    let adjacent: Cord[] = []

    //- loop over all alive cells
    this._cells.forEach((cell: CellLight) => {
      const dead = this.get_dead_neighbors(cell.grid_xy())
      const dead_points: Cord[] = []
      dead.forEach((c: CellLight) => {
        dead_points.push({
          x: c.grid_xy().x,
          y: c.grid_xy().y
        })
      })
      adjacent = adjacent.concat(dead_points)
    })

    //- filter duplicates
    return adjacent.filter((c: Cord, i: number, self) =>
      i === self.findIndex((t: Cord) => (
        t.x === c.x && t.y === c.y
      ))
    )
  }

  public cells(): CellLight[] {
    return this._cells
  }

  //- converts 1d array index into 2d array x,y index
  public mapto(i: number): Cord {
    let p: Cord = { x: -1, y: -1 }
    let c: number = this.col_count
    let r: number = this.row_count
    if (i < c * r && i >= 0)
      p = { x: i % c, y: Math.floor(i / c) }
    return p
  }

  public draw() {
    this._gfx.clear()
    this._cells.forEach((c: CellLight) => c.draw(this._gfx, this.rgb))
  }
}