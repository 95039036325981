export interface Size {
  w: number,
  h: number
}

export interface Cord {
  x: number;
  y: number
}

export interface Color {
  r: number,
  g: number,
  b: number
}

export enum CellState {
  alive,
  dead
}