import { Size, Cord } from "../interfaces"

//- a collection of patterns for conways game of life

export class Pattern {
  protected _grid_count: Size = { w: 150, h: 150 }
  protected _base: Cord = { x: 0, y: 0 }
  constructor() { }

  public set base(c: Cord) { this._base = c }  //- TOTO: bounds check
  public set grid_count(s: Size) { this._grid_count = s }
  public build(): Cord[] {
    console.log('pattern build')
    return []
  }

  protected v_line(base: Cord, height: number): Cord[] {
    let cells: Cord[] = []
    for (let y = 0; y < height; y++) {
      cells.push({ x: base.x, y: base.y + y })
    }
    return cells
  }

  protected h_line(base: Cord, width: number): Cord[] {

    let cells: Cord[] = []
    for (let x = 0; x < width; x++) {
      cells.push({ x: base.x + x, y: base.y })
    }
    return cells
  }




  public build_die_hard(base: Cord): void {
    // this.activate({ x: base.x + 1, y: base.y + 2 }, true)
    // this.activate({ x: base.x + 2, y: base.y + 2 }, true)
    // this.activate({ x: base.x + 2, y: base.y + 3 }, true)
    // this.activate({ x: base.x + 7, y: base.y + 1 }, true)
    // this.activate({ x: base.x + 7, y: base.y + 3 }, true)
    // this.activate({ x: base.x + 6, y: base.y + 3 }, true)
    // this.activate({ x: base.x + 8, y: base.y + 3 }, true)
  }


}