//- broadcat patterns at intervals

import { GeneratorItem } from "./GeneratorItem"
import { Cord } from "../interfaces"
import { Pattern } from "./Pattern"

export interface GeneratorPayload {
  cells: Cord[]
}

export class Generator {

  private _items: GeneratorItem[] = []

  public create(p: Pattern) {
    let gi = new GeneratorItem(p)
    gi.start()
    this._items.push(gi)
  }

  public get items(): GeneratorItem[] {
    return this._items
  }

  public stop_all() {
    console.log('stop_all')
    this._items.forEach((gi: GeneratorItem) => {
      console.log('gi.stop()')
      gi.stop()
    });
  }

}