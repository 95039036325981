import * as PIXI from 'pixi.js'
import { GameOfLife } from "./gol/GameOfLife"
// import { gsap } from 'gsap'
// import { Viewport } from 'pixi-viewport'

// import { PixiPlugin } from 'gsap/PixiPlugin'
// PixiPlugin.registerPIXI(PIXI)
// gsap.registerPlugin(PixiPlugin)

let app = new PIXI.Application()
let canvas = document.createElement('div')
canvas.id = 'game'
canvas.appendChild(app.view)
const section: HTMLElement | null = document.querySelector('section')
if (section) section.appendChild(canvas)


window.addEventListener('resize', () => { resize(app) })
resize(app)
// app.renderer
// test_draw(app)

let gol = new GameOfLife()
app.stage.addChild(gol.container())
gol.set_html_nav(document)
gol.start()






// viewport.moveCorner(0, 0)
// viewport.clamp({ direction: 'all' })
// viewport.addChild(grid.getContainer())
// grid.interval()




// Resize function window
function resize(a: PIXI.Application): void {
  const section: HTMLElement | null = document.querySelector('section')
  if (section) {
    // console.log('section', section.clientWidth, section.clientHeight)
    a.renderer.resize(section.clientWidth, section.clientHeight - 210)
  }
}