
import { Cord, Color, CellState } from "./interfaces"

//- TODO: convert timer to world game loop
export class CellLight {
  private life_duration: number = 2000
  // private life_duration_timer: number = -1
  // private immunity_timer: number = -1
  private immunity_duration: number = 10
  private _immunity: boolean = false
  private _new_state: CellState = CellState.dead
  private _state: CellState = CellState.dead
  private color_alive: number = 0x000044
  private color_dead: number = 0x440000
  private color: number = this.color_alive
  private grid_position: Cord = { x: -1, y: -1 }

  constructor(
    private birth_time: number,
    private position: Cord, //- container x,y
    private width: number,
    private height: number,
  ) {
    this.grid_position = {
      x: Math.floor(position.x / width),
      y: Math.floor(position.y / height)
    }

    //- doooomed
    // this.life_duration_timer = <any>setTimeout(() => this.death(), this.life_duration)
  }

  public reduce_life(time_now: number): void {

    if (this._state == CellState.alive)
      if ((time_now - this.birth_time) > this.life_duration)
        this.death()
  }

  public remove_immunity(): void {
    this._immunity = false
  }

  public grant_immunity(): void {
    this._immunity = true
  }

  public reduce_immunity(time_now: number) {
    if (this._immunity) {
      if ((time_now - this.birth_time) > this.immunity_duration)
        this.remove_immunity()
    }
  }

  public immune(): boolean {
    return this._immunity
  }

  public draw(gfx: PIXI.Graphics, rgb: Color) {
    const { x } = this.position,
      { y } = this.position,
      w = this.width,
      h = this.height
    var decColor = 0x1000000 + rgb.b + 0x100 * rgb.g + 0x10000 * rgb.r
    gfx.beginFill(parseInt('0x' + decColor.toString(16).substr(1)))
    gfx.drawRect(x, y, w, h)
  }

  public birth(): void {
    this._new_state = CellState.alive
    // this.color = this.color_alive
    // this.color(this.color_alive)
  }

  public death(): void {
    if (!this._immunity) this._new_state = CellState.dead
    // this.color(this.color_default)
  }

  public update_state(): void {
    this._state = this._new_state
  }

  public state(): CellState {
    return this._state
  }

  public grid_xy(): Cord {
    return this.grid_position
  }

  public new_state(): CellState {
    return this._new_state
  }

}