
import { Pattern } from "./Pattern"
import { Cord } from "../interfaces"

export class GeneratorItem {

  // private collection: Pattern = new Pattern()
  private interval_rate: number = 1000 //- miliseconds
  private interval: number = -1 //- interval ID


  constructor(private _pattern: Pattern) {

  }

  public get pattern(): Pattern { return this._pattern }

  public change(ms: number): void {

    this.interval_rate = ms
    this.stop()
    this.start()
  }

  public toggle(): void {

  }

  public start(): void {
    this.interval = window.setInterval(() => {
      let cells: Cord[] = this._pattern.build()
      //- trigger custom event
      const event = new CustomEvent('collection', { detail: cells })
      document.dispatchEvent(event)

    }, this.interval_rate)
  }

  public stop(): void {
    window.clearInterval(this.interval) //- stop current
  }


}