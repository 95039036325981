// if alive: fewer than two live neighbours dies
// if alive: more than three live neighbours dies
// if alive: two or three live neighbours lives on


// if dead:  exactly three live neighbours becomes a live cell
import * as PIXI from 'pixi.js'
import { CellLight } from "./CellLight"
import { World } from "./World"
import { Cord, Color, CellState } from "./interfaces"
import { Generator } from "./Patterns/Generator"
import { GeneratorItem } from "./Patterns/GeneratorItem"
import { Acorn } from "./Patterns/Acorn"
import { PillarPulse } from "./Patterns/PillarPulse"

var xolor = require('xolor')

export class GameOfLife {
  private fps_cap: number = 10
  private fps_max: number = 30

  private generator: Generator = new Generator()
  private _container: PIXI.Container = new PIXI.Container
  private mouse_layer: PIXI.Graphics = new PIXI.Graphics
  protected mouse_down: boolean = false
  protected style = new PIXI.TextStyle({
    fill: '#414754'
  })
  public label = new PIXI.Text('', this.style);


  private world: World = new World()
  // private ticker: PIXI.Ticker = new PIXI.Ticker()
  // private timerId: NodeJS.Timeout | number = -1

  private gradient_i: number = 0
  private gradients: Color[] = this.random_gradient(this.world.rgb)

  constructor() {
    //- seed color 
    this.world.rgb = {
      r: Math.floor(Math.random() * 50) + 200,
      g: Math.floor(Math.random() * 0),
      b: Math.floor(Math.random() * 0)
    }

    //- setup containers
    this._container.addChild(this.gfx())
    this._container.addChild(this.mouse_layer)
    // this._container.addChild(this.label)

    //- setup mouse interactions
    this.setup_interactive()



  }

  //
  //- targets HTML dom for nav#mainnav
  //
  public set_html_nav(document: Document) {
    // let nav: HTMLElement = document.createElement('nav')
    // nav.id = 'Game-Of-Life-nav'
    // nav.innerHTML = `
    // `
    // nav.classList.add('sub-menu')
    // nav.classList.add('pl-3')
    // nav.setAttribute('style', 'bottom:55px')
    // document.body.append(nav)

    //
    //- FREQUENCY
    //
    let range: HTMLElement | null = document.querySelector("input#formControlRange")
    if (range) range.addEventListener('input', (ev: Event) => {
      if (ev.target instanceof HTMLInputElement) {
        let label: HTMLElement | null = document.querySelector("span#pillars-frequency")
        const label_value: string = ev.target.value
        if (label) label.innerHTML = label_value

        //- update pillars interval...
        this.generator.items.forEach((gi: GeneratorItem) => {
          if (gi.pattern instanceof PillarPulse) {
            gi.change(parseInt(label_value))
          }
        })
        // this.world.change_interval(parseInt(ev.target.value))
      }
    })

    //
    //- COUNT
    //
    range = document.querySelector("input#pillarsCountRange")
    if (range) range.addEventListener('input', (ev: Event) => {
      if (ev.target instanceof HTMLInputElement) {
        let label: HTMLElement | null = document.querySelector("span#pillars-count")
        if (label) label.innerHTML = ev.target.value
      }
    })

    //- create callback for button
    let btn: HTMLElement | null = document.querySelector("button#clear-gol")
    if (btn) btn.addEventListener('click', (e: MouseEvent) => {
      this.world.clear()
    })

    //
    //- button click event --- PILLARS -
    //
    btn = document.querySelector("button#pillars-options-randomize")
    if (btn) btn.addEventListener('click', (e: MouseEvent) => {
      e.preventDefault()

      if (e.target instanceof Element) {
        const is_pressed: Attr | null = e.target.attributes.getNamedItem('aria-pressed')
        if (is_pressed instanceof Attr) {
          if (is_pressed.value == 'false') {
            this.generator.items.forEach((gi: GeneratorItem) => {
              if (gi.pattern instanceof PillarPulse) {
                gi.pattern.random = true
              }
            })
          }
          else {
            this.generator.items.forEach((gi: GeneratorItem) => {
              if (gi.pattern instanceof PillarPulse) {
                gi.pattern.random = false
              }
            })
          }
        }
      }

    })


    //
    //- ABOUT
    //
    btn = document.querySelector("button#about-gol")
    if (btn) btn.addEventListener('click', (e: MouseEvent) => {
      // this.world.clear()
    })

    //
    //- ACORN
    //
    btn = document.querySelector("button#acorn-gol")
    if (btn) btn.addEventListener('click', (e: MouseEvent) => {
      let a: Acorn = new Acorn()
      let c: Cord = this.world.get_random_cell()
      c.y -= 5
      a.base = c
      const pattern: Cord[] = a.build()
      this.world.activate_pattern(pattern)
    })

    //
    //- button click event --- PILLARS 
    //
    btn = document.querySelector("button#pillars-gol")
    if (btn) btn.addEventListener('click', (e: MouseEvent) => {

      //- on or off?
      if (e.target instanceof Element) {
        const is_pressed: Attr | null = e.target.attributes.getNamedItem('aria-pressed')

        if (is_pressed instanceof Attr) {

          if (is_pressed.value == 'false') {
            //- start it up!
            let a: PillarPulse = new PillarPulse(this.world.grid_count())
            this.generator.create(a)

            //- start listening!
            document.addEventListener('collection', this.collection_handler.bind(this))
          }
          else {
            console.log('stop all event')
            this.generator.stop_all()
            //- shut it down
            document.removeEventListener('collection', this.collection_handler)
          }
        }
      }
    })

    //- set modal about html
    let modal: HTMLElement = document.createElement('div')
    const html: string = `

    `
    modal.innerHTML = html
    // document.body.append(modal)
  }

  public collection_handler(e: any): void {
    if (e instanceof CustomEvent) {
      e.detail.forEach((c: Cord) => {
        this.world.activate(c, true)
      })
    }

  }

  private setup_interactive(): void {
    this.mouse_layer.beginFill(0x2200aa)
    const size = this.world.grid_size()
    this.mouse_layer.drawRect(0, 0, size.w, size.h)
    this.mouse_layer.alpha = 0

    //- setup mouse events
    this.mouse_layer.interactive = true
    this.mouse_layer.on('mousedown', (event: PIXI.interaction.InteractionEvent) => {
      this.mouse_down = true

    })

    this.mouse_layer.on('mouseup', (event: PIXI.interaction.InteractionEvent) => {
      this.mouse_down = false

    })

    this.mouse_layer.on('mousemove', (event: PIXI.interaction.InteractionEvent) => {
      if (this.mouse_down)
        this.input_drag(event.data.global)
    })

    this.mouse_layer.on('touchmove', (event: PIXI.interaction.InteractionEvent) => {
      this.input_drag(event.data.global)
    })
  }



  start(): void {

    let ticker = PIXI.Ticker.shared;
    let { loop } = this,
      { world } = this,
      fps = this.label

    ticker.maxFPS = this.fps_max
    ticker.autoStart = false
    ticker.stop()

    ticker.add((delta: number) => {
      const time: number = ++world.raw_time

      if (time % (ticker.maxFPS / 2) == 0) {
        fps.text = '' + Math.floor(ticker.FPS)
        this.gradient_i += 1
        if (this.gradient_i == this.gradients.length) {
          this.gradient_i = 0
          this.gradients = this.random_gradient(this.world.rgb)
        }
        world.rgb = this.gradients[this.gradient_i]
      }
      //- check if low frame rate, if so, kill random cells
      const fps_now: number = PIXI.Ticker.shared.FPS
      // const fps_max: number = PIXI.Ticker.shared.maxFPS

      loop(world)

      if (time > 500)
        if (fps_now < this.fps_cap)
          world.kill_first_cell()
    })

    ticker.start()

    this.world.build()

    //- start a pillar pulse
    let a: PillarPulse = new PillarPulse(this.world.grid_count())
    this.generator.create(a)

    //- start listening!
    document.addEventListener('collection', this.collection_handler.bind(this))
  }



  public input_drag(point: PIXI.Point): void {
    let grid_xy: Cord = this.world.to_grid_cell_space(point)
    let c: CellLight = this.world.get_cell(grid_xy)
    const fps_now: number = PIXI.Ticker.shared.FPS
    // const fps_max: number = PIXI.Ticker.shared.maxFPS
    if (c.state() == CellState.dead)
      if (fps_now > this.fps_cap)
        this.world.activate(grid_xy, true)
  }

  //- from current color, to a new one
  public random_gradient(start: Color): Color[] {
    // var c = xolor()
    var gradientColors: Color[] = []

    const start_xolor = `rgb(${start.r},${start.g},${start.b})`

    let pick: number = Math.floor(Math.random() * 4)
    let colors: string[] = ['0', '0', '0']
    // colors[pick] = '' + Math.floor(Math.random() * 255)
    //  `rgb(${colors[0]},${colors[1]},${colors[2]})`
    const end_xolor = xolor.random()


    var c = xolor(start_xolor)
    for (var n = 0; n < 8; n++) {
      gradientColors.push(c.gradient(end_xolor, n / 8))
    }

    return gradientColors
  }


  private loop(world: World): void {


    const grid_count = world.grid_count()
    let births: Cord[] = []

    //- check dead cells for life condition if FPS allows
    //- check if low frame rate, if so, kill random cells
    const fps_now: number = PIXI.Ticker.shared.FPS
    const fps_max: number = PIXI.Ticker.shared.maxFPS

    //- only spawn if FPS allows
    if (fps_now > (fps_max * 0.75)) {
      let dead_cells: Cord[] = world.get_adjacent_cells()
      dead_cells.forEach((c: Cord) => {
        let cell: CellLight = world.get_cell(c)
        if (cell.state() === CellState.dead) {
          const n = world.get_alive_neighbors(c)
          if (n.length == 3) {
            births.push(c)
          }
        }
      })
    }



    //- check each living cell for death condition
    world.cells().forEach((cell: CellLight, i: number) => {
      cell.reduce_life(world.raw_time)
      cell.reduce_immunity(world.raw_time)
      let n = world.get_alive_neighbors(cell.grid_xy())
      if (n.length < 2 || n.length > 3) cell.death()
      //- if coordinates are outside grid, die
      if (cell.grid_xy().x > world.grid_count().w ||
        cell.grid_xy().y > world.grid_count().h ||
        cell.grid_xy().x < 0 || cell.grid_xy().y < 0)
        cell.death()
    })

    // if (births.length)



    world.remove_dead_cells()
    world.add_birth_cells(births)


    //- redraw world
    world.draw()


    // debugger

  }

  public gfx(): PIXI.Graphics {
    return this.world.gfx()
  }

  public container(): PIXI.Container {
    return this._container
  }
}



